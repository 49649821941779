<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="float-right d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <div class="d-flex">
                            <div>
                                <h3 class="mb-2">
                                    Datos del concepto contable
                                </h3>
                                <div class="text-gray mb-5">
                                    Por favor registre los datos del concepto
                                    contable para continuar
                                </div>
                            </div>
                        </div>
                        <b-row v-hotkey="keymap">
                            <b-col cols="12" md="3" lg="3" xl="3">
                                <b-form-group>
                                    <label for="codigo"
                                        >Código
                                        <small style="color: red">*</small>
                                    </label>
                                    <b-form-input
                                        id="codigo"
                                        type="text"
                                        placeholder="Ej: 001"
                                        v-model="
                                            $v.conceptoContable.codigo.$model
                                        "
                                        :state="validateState('codigo')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.conceptoContable.codigo
                                                .$anyError
                                        "
                                    >
                                        ({{
                                            $v.conceptoContable.codigo.$model
                                                .length
                                        }}
                                        /
                                        {{
                                            $v.conceptoContable.codigo.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.conceptoContable.codigo
                                                .maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.conceptoContable.codigo.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.conceptoContable.codigo.required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="9" lg="9" xl="9">
                                <b-form-group>
                                    <label for="nombre_comercial"
                                        >Descripción
                                        <small style="color: red">*</small>
                                    </label>
                                    <b-form-input
                                        id="descripcion"
                                        type="text"
                                        placeholder="Ej: Mi concepto contable"
                                        v-model="
                                            $v.conceptoContable.descripcion
                                                .$model
                                        "
                                        :state="validateState('descripcion')"
                                        :disabled="read_only"
                                    >
                                    </b-form-input>
                                    <div
                                        class="length-field mt-1"
                                        v-if="
                                            !$v.conceptoContable.descripcion
                                                .$anyError
                                        "
                                    >
                                        ({{
                                            $v.conceptoContable.descripcion
                                                .$model.length
                                        }}
                                        /
                                        {{
                                            $v.conceptoContable.descripcion
                                                .$params.maxLength.max
                                        }})
                                    </div>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.conceptoContable.descripcion
                                                .maxLength
                                        "
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.conceptoContable.descripcion
                                                .$params.maxLength.max
                                        }}
                                        caracteres
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.conceptoContable.descripcion
                                                .required
                                        "
                                    >
                                        Campo requerido
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-md-4">
                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="pucVentas">Puc ventas </label>
                                    <jautocomplete
                                        id="pucVentas"
                                        :items="puc"
                                        item-text="descripcion"
                                        item-value="id"
                                        return-object
                                        open-on-focus
                                        show-create-button
                                        no-null
                                        load-async
                                        @text="filterPucData"
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-puc-modal'
                                                )

                                                pucKey = 'pucVentas'
                                            }
                                        "
                                        :disabled="read_only"
                                        v-model="
                                            $v.conceptoContable.pucVentas.$model
                                        "
                                        :custom-filter="filterPuc"
                                    >
                                        <template v-slot:option="{ item }">
                                            <option
                                                :class="`text-${
                                                    item.tipo === 'S'
                                                        ? 'secondary'
                                                        : 'gray'
                                                }`"
                                                :disabled="item.tipo === 'S'"
                                            >
                                                {{ item.codigo }} -
                                                {{ item.descripcion }}
                                            </option>
                                        </template>
                                    </jautocomplete>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="pucDevolucionVentas"
                                        >Puc devolución ventas</label
                                    >
                                    <jautocomplete
                                        id="pucDevolucionVentas"
                                        :items="puc"
                                        item-text="descripcion"
                                        item-value="id"
                                        return-object
                                        open-on-focus
                                        show-create-button
                                        no-null
                                        load-async
                                        @text="filterPucData"
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-puc-modal'
                                                )

                                                pucKey = 'pucDevolucionVentas'
                                            }
                                        "
                                        :disabled="read_only"
                                        v-model="
                                            $v.conceptoContable
                                                .pucDevolucionVentas.$model
                                        "
                                        :custom-filter="filterPuc"
                                    >
                                        <template v-slot:option="{ item }">
                                            <option
                                                :class="`text-${
                                                    item.tipo === 'S'
                                                        ? 'secondary'
                                                        : 'gray'
                                                }`"
                                                :disabled="item.tipo === 'S'"
                                            >
                                                {{ item.codigo }} -
                                                {{ item.descripcion }}
                                            </option>
                                        </template>
                                    </jautocomplete>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="pucCompras">Puc compras</label>
                                    <jautocomplete
                                        id="pucCompras"
                                        :items="puc"
                                        item-text="descripcion"
                                        item-value="id"
                                        return-object
                                        open-on-focus
                                        show-create-button
                                        no-null
                                        load-async
                                        @text="filterPucData"
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-puc-modal'
                                                )

                                                pucKey = 'pucCompras'
                                            }
                                        "
                                        :disabled="read_only"
                                        v-model="
                                            $v.conceptoContable.pucCompras
                                                .$model
                                        "
                                        :custom-filter="filterPuc"
                                    >
                                        <template v-slot:option="{ item }">
                                            <option
                                                :class="`text-${
                                                    item.tipo === 'S'
                                                        ? 'secondary'
                                                        : 'gray'
                                                }`"
                                                :disabled="item.tipo === 'S'"
                                            >
                                                {{ item.codigo }} -
                                                {{ item.descripcion }}
                                            </option>
                                        </template>
                                    </jautocomplete>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row class="mt-md-5">
                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="pucDevolucionCompras"
                                        >Puc devolución compras</label
                                    >
                                    <jautocomplete
                                        id="pucDevolucionCompras"
                                        :items="puc"
                                        item-text="descripcion"
                                        item-value="id"
                                        return-object
                                        open-on-focus
                                        show-create-button
                                        no-null
                                        load-async
                                        @text="filterPucData"
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-puc-modal'
                                                )

                                                pucKey = 'pucDevolucionCompras'
                                            }
                                        "
                                        :disabled="read_only"
                                        v-model="
                                            $v.conceptoContable
                                                .pucDevolucionCompras.$model
                                        "
                                        :custom-filter="filterPuc"
                                    >
                                        <template v-slot:option="{ item }">
                                            <option
                                                :class="`text-${
                                                    item.tipo === 'S'
                                                        ? 'secondary'
                                                        : 'gray'
                                                }`"
                                                :disabled="item.tipo === 'S'"
                                            >
                                                {{ item.codigo }} -
                                                {{ item.descripcion }}
                                            </option>
                                        </template>
                                    </jautocomplete>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="pucEntradasEspeciales"
                                        >Puc entradas especiales</label
                                    >
                                    <jautocomplete
                                        id="pucEntradasEspeciales"
                                        :items="puc"
                                        item-text="descripcion"
                                        item-value="id"
                                        return-object
                                        open-on-focus
                                        show-create-button
                                        no-null
                                        load-async
                                        @text="filterPucData"
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-puc-modal'
                                                )

                                                pucKey = 'pucEntradasEspeciales'
                                            }
                                        "
                                        :disabled="read_only"
                                        v-model="
                                            $v.conceptoContable
                                                .pucEntradasEspeciales.$model
                                        "
                                        :custom-filter="filterPuc"
                                    >
                                        <template v-slot:option="{ item }">
                                            <option
                                                :class="`text-${
                                                    item.tipo === 'S'
                                                        ? 'secondary'
                                                        : 'gray'
                                                }`"
                                                :disabled="item.tipo === 'S'"
                                            >
                                                {{ item.codigo }} -
                                                {{ item.descripcion }}
                                            </option>
                                        </template>
                                    </jautocomplete>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group>
                                    <label for="pucSalidasEspeciales"
                                        >Puc salidas especiales</label
                                    >
                                    <jautocomplete
                                        id="pucSalidasEspeciales"
                                        :items="puc"
                                        item-text="descripcion"
                                        item-value="id"
                                        return-object
                                        open-on-focus
                                        show-create-button
                                        no-null
                                        load-async
                                        @text="filterPucData"
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-puc-modal'
                                                )

                                                pucKey = 'pucSalidasEspeciales'
                                            }
                                        "
                                        :disabled="read_only"
                                        v-model="
                                            $v.conceptoContable
                                                .pucSalidasEspeciales.$model
                                        "
                                        :custom-filter="filterPuc"
                                    >
                                        <template v-slot:option="{ item }">
                                            <option
                                                :class="`text-${
                                                    item.tipo === 'S'
                                                        ? 'secondary'
                                                        : 'gray'
                                                }`"
                                                :disabled="item.tipo === 'S'"
                                            >
                                                {{ item.codigo }} -
                                                {{ item.descripcion }}
                                            </option>
                                        </template>
                                    </jautocomplete>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        v-b-popover.hover.top="'Presione esc'"
                        size="md"
                        class="mr-4"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        class="d-flex align-items-center"
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <crearPucModalVue @newPuc="setNewPuc" />
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    addListenerCommands,
    removeListenerCommands,
    compareObjects,
    goToNextField
} from '@/utils/others'
import { filterPuc } from '@/utils/filters'
import crearPucModalVue from '../../../contabilidad/configuracion/puc/crearPucModal.vue'

const { API } = services

const defaulConceptoContable = () =>
    JSON.parse(
        JSON.stringify({
            codigo: '',
            descripcion: '',
            pucVentas: null,
            pucDevolucionVentas: null,
            pucCompras: null,
            pucDevolucionCompras: null,
            pucEntradasEspeciales: null,
            pucSalidasEspeciales: null,
            empresa_id: null
        })
    )

export default {
    name: 'CrearConceptoContable',
    mixins: [validationMixin],
    components: { jautocomplete, crearPucModalVue },
    data: () => ({
        puc: [],
        pucKey: null,
        conceptoContableCopy: defaulConceptoContable(),
        conceptoContable: defaulConceptoContable(),
        isLoading: false,
        isSaved: true,
        read_only: false
    }),
    watch: {
        conceptoContable: {
            handler(newValue) {
                this.isSaved = compareObjects(
                    this.conceptoContableCopy,
                    newValue
                )
            },
            deep: true
        }
    },
    async mounted() {
        this.goToNextField('codigo')
        addListenerCommands(this)
        // await this.getPuc()
        await this.getParams()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        conceptoContable: {
            codigo: {
                required,
                maxLength: maxLength(25)
            },
            descripcion: {
                required,
                maxLength: maxLength(150)
            },
            pucVentas: {},
            pucDevolucionVentas: {},
            pucCompras: {},
            pucDevolucionCompras: {},
            pucEntradasEspeciales: {},
            pucSalidasEspeciales: {}
        }
    },
    methods: {
        goToNextField,
        showLog(data) {
            console.log(data)
        },
        filterPuc,
        setNewPuc(pucItem) {
            this.puc.push(pucItem)
            this.conceptoContable[this.pucKey] = pucItem
            this.pucKey = null
        },
        async getParams() {
            const { concepto_contable_id } = this.$route.params
            if (concepto_contable_id) {
                await this.getConceptoContableById(concepto_contable_id)
            }

            this.read_only = this.$route.meta.read_only
        },
        filterPucData(text) {
            if (text && text.length > 3) {
                this.getPuc({ term: text })
            }
        },
        async getPuc({ term }) {
            await API.POST({
                url: 'contabilidad/puc/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.puc = dato.content
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        async getConceptoContableById(id) {
            // console.log('acá 2');
            await API.GET({
                url: `administracion/concepto-contable/${id}`
            })
                .then(async (response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        await this.getPuc({
                            term: dato.pucVentas.descripcion
                        })

                        await this.getPuc({
                            term: dato.pucDevolucionVentas.descripcion
                        })

                        await this.getPuc({
                            term: dato.pucCompras.descripcion
                        })

                        await this.getPuc({
                            term: dato.pucDevolucionCompras.descripcion
                        })

                        await this.getPuc({
                            term: dato.pucEntradasEspeciales.descripcion
                        })

                        await this.getPuc({
                            term: dato.pucSalidasEspeciales.descripcion
                        })

                        this.conceptoContableCopy = { ...dato }
                        this.conceptoContable = { ...dato }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        save() {
            const valid = !this.$v.conceptoContable.$invalid
            if (valid) {
                this.isLoading = true

                const HTTP = this.conceptoContable.id ? API.PATCH : API.POST
                const p_opc = this.conceptoContable.id ? 'UPDATE' : 'INSERT'

                this.conceptoContable.empresaId = this.getUltimaEmpresa.id

                HTTP({
                    url: 'administracion/concepto-contable',
                    data: {
                        p_datajson: {
                            ...this.conceptoContable
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            // this.empresa = defaultEmpresa()
                            this.back()
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.conceptoContable.$touch()
            }
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.conceptoContable[key]
            return $dirty ? !$error : null
        }
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 26 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapGetters('empresas', ['getUltimaEmpresa'])
    }
}
</script>

<style></style>
